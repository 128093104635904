import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import AllWebinars from '../../components/allWebinars'
import { Button } from '../../components/global'

const W005 = () => (
  <Layout includeNotice={false}>
    <SEO title="Webinar 005: Understanding &amp; Troubleshooting Solenoid Circuits (Part 1 of 2)" />
    <div className="container container-last">
      <h1>
        Webinar 005:
        <br />
        Understanding &amp; Troubleshooting Solenoid Circuits (Part 1 of 2)
      </h1>
      <hr />
      <p>
        Solenoids or coils are electrical components that are activated when an electron current
        passes through it. Solenoids are used to control electrical circuits, mechanical devices and
        to direct the path of hydraulic fluid in vehicle systems.
      </p>
      <p>
        This webinar covers ON/OFF solenoid operation in DC circuits and troubleshooting solenoids
        in circuits is explained. Here are some points that will be covered. What happens when a
        solenoid powers down? Is voltage measurement or electron current best to test a solenoid
        circuit? Why put a resistor in series with a solenoid? Why put a diode in parallel with a
        solenoid? Is a resistor or diode better to use for spike voltage suppression? Includes some
        things about solenoids you may never have known before.
      </p>
      <p>
        Get the answers in this webinar. When you register you will receive a link to print out a
        copy of the webinar handout. Have it ready to take notes. (Part 2 Solenoids and Computers
        follows in a few weeks.)
      </p>
      <ul style={{ marginBottom: 0 }}>
        <li>Watch Webinar W005 Session</li>
        <li>
          Purchase a link to download the Webinar W005 recording and watch as often as you like.
          (Download link expires after 7 days)
        </li>
      </ul>
      <Button
        type="secondary"
        text="BUY NOW for $39.00"
        href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=66c971535bfd44d797980967901f4dbe"
      />

      <hr />
      <AllWebinars />
    </div>
  </Layout>
)

export default W005
